import React, { useState } from "react";
import Box from "@mui/material/Box";
import { HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./../common/navbar";
import ClientNavbar from "./../common/clientNavbar";
import Dashboard from "./../dashboard/Dashboard";
import Login from "./../login/Login";
import ClientLogin from "../login/clientLogin";
import { ToastContainer } from "react-toastify";

import InstrumentMasterList from "../instrumentMaster/instrumentMasterList";
import PlantMasterList from "../plantMaster/plantMasterList";
import EditinstrumentMaster from "../instrumentMaster/editinstrumentMaster";
import Tables from "../../utils/components/tables";
import EditPlantMaster from "../plantMaster/editPlantMaster";
import LabMasterList from "../labMaster/labMasterList";
import EditLabMaster from "../labMaster/editLabMaster";
import VendorMasterList from "../vendorMaster/vendorMasterList";
import EditVendorMaster from "../vendorMaster/editVendorMaster";
import UserList from "../user/userList";
import EditUser from "../user/editUser";
import CalibrationRegisterList from "../calibrationRegister/calibrationRegisterList";
import EditCalibrationRegister from "../calibrationRegister/editCalibrationRegister";
import InstrumentStatusList from "../instrumentStatus/instrumentStatusList";
import EditInstrumentStatus from "../instrumentStatus/editInstrumentStatus";
import SchedulerList from "../scheduler/schedulerList";
import HistoryList from "../history/historyList";
import SummaryList from "../summary/summaryList";
import UnitsList from "../units/unitsList";
import EditUnits from "../units/editUnits";
import InstrumentList from "../instrument/instrumentList";
import EditInstrument from "../instrument/editInstrument";
import CalibrationRegisterHistory from "../calibrationRegister/calibrationRegisterHistory";
import InstrumentInOutList from "../instrumentInOut/instrumentInOutList";
import EditInstrumentInOut from "../instrumentInOut/editInstrumentInOut";
import CreateInstrumentInOut from "../instrumentInOut/createInstrumentInOut";
import AllocatedReport from "../allocatedReport/allocatedReport";
import InstrumentInOutReport from "../instrumentInOut/instrumentInOutReport";
import AuditTable from "../audit/AuditTable";
import ViewLogs from "../audit/viewLogs";
import CreateDocument from "../documentUpload/createDocument";
import ViewDocument from "../documentUpload/viewDocument";

export default function Main(props) {
  const [title, setTitle] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );

  let PropsMode = props.mode;
  let PropsSetMode = props.setMode;
  let PropsSetThemecolor = props.setThemecolor;

  function checkAuthTokenAndRedirect() {
    sessionStorage.removeItem("isLoggedIn");
    // Check if "authToken" is available in the session store
    const authToken = sessionStorage.getItem("authToken");
    const currentUrl = window.location.href;
    const allowedRoute = `${window.location.origin}/#/certificate/print/`;
    if (!authToken && currentUrl.startsWith(allowedRoute)) {
      return;
    }
    if (!authToken) {
      window.localStorage.clear();
    }
    window.location.href = "/";
  }

  if (!sessionStorage.getItem("logInUser")) {
    checkAuthTokenAndRedirect();
    sessionStorage.setItem("logInUser", "true");
  }

  const routes = [
    {
      path: "/",
      element: localStorage.getItem("isLoggedIn") ? (
        <Dashboard />
      ) : (
        <Login setIsLoggedIn={setIsLoggedIn} setLoader={props.setLoader} />
      ),
    },
    {
      path: "/test",
      element: <Tables setLoader={props.setLoader} />,
    },
    {
      path: "/clientLogin",
      element: <ClientLogin setLoader={props.setLoader} />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/instrumentList",
      element: <InstrumentList setLoader={props.setLoader} />,
    },
    {
      path: "/editInstrument",
      element: <EditInstrument setLoader={props.setLoader} />,
    },
    {
      path: "/editInstrument/:id",
      element: <EditInstrument setLoader={props.setLoader} />,
    },
    {
      path: "/instrumentMasterList",
      element: <InstrumentMasterList setLoader={props.setLoader} />,
    },
    {
      path: "/instrumentMasterList/count",
      element: (
        <InstrumentMasterList setLoader={props.setLoader} isCount={true} />
      ),
    },

    {
      path: "/instrumentMasterList/assign",
      element: (
        <InstrumentMasterList setLoader={props.setLoader} isAssign={true} />
      ),
    },

    {
      path: "/editInstrumentMaster",
      element: <EditinstrumentMaster setLoader={props.setLoader} />,
    },
    {
      path: "/editInstrumentMaster/:id",
      element: <EditinstrumentMaster setLoader={props.setLoader} />,
    },
    {
      path: "/plantMasterList",
      element: <PlantMasterList setLoader={props.setLoader} />,
    },
    {
      path: "/editPlantMaster",
      element: <EditPlantMaster setLoader={props.setLoader} />,
    },
    {
      path: "/editPlantMaster/:id",
      element: <EditPlantMaster setLoader={props.setLoader} />,
    },
    {
      path: "/labMasterList",
      element: <LabMasterList setLoader={props.setLoader} />,
    },
    {
      path: "/editLabMaster",
      element: <EditLabMaster setLoader={props.setLoader} />,
    },
    {
      path: "/editLabMaster/:id",
      element: <EditLabMaster setLoader={props.setLoader} />,
    },
    {
      path: "/vendorMasterList",
      element: <VendorMasterList setLoader={props.setLoader} />,
    },
    {
      path: "/editVendorMaster",
      element: <EditVendorMaster setLoader={props.setLoader} />,
    },
    {
      path: "/editVendorMaster/:id",
      element: <EditVendorMaster setLoader={props.setLoader} />,
    },
    {
      path: "/usersList",
      element: <UserList setLoader={props.setLoader} />,
    },
    {
      path: "/editUser",
      element: <EditUser setLoader={props.setLoader} />,
    },
    {
      path: "/editUser/:id",
      element: <EditUser setLoader={props.setLoader} />,
    },
    {
      path: "/calibrationRegisterList",
      element: <CalibrationRegisterList setLoader={props.setLoader} />,
    },
    {
      path: "/editCalibrationRegister",
      element: <EditCalibrationRegister setLoader={props.setLoader} />,
    },
    {
      path: "/editCalibrationRegister/:id",
      element: <EditCalibrationRegister setLoader={props.setLoader} />,
    },
    {
      path: "/calibrationRegisterHistory/:calibrationId",
      element: <CalibrationRegisterHistory setLoader={props.setLoader} />,
    },
    {
      path: "/unitsList",
      element: <UnitsList setLoader={props.setLoader} />,
    },
    {
      path: "/editUnits",
      element: <EditUnits setLoader={props.setLoader} />,
    },
    {
      path: "/editUnits/:id",
      element: <EditUnits setLoader={props.setLoader} />,
    },
    {
      path: "/instrumentStatusList",
      element: <InstrumentStatusList setLoader={props.setLoader} />,
    },
    {
      path: "/editInstrumentStatus",
      element: <EditInstrumentStatus setLoader={props.setLoader} />,
    },
    {
      path: "/editInstrumentStatus/:id",
      element: <EditInstrumentStatus setLoader={props.setLoader} />,
    },
    {
      path: "/schedulerList",
      element: <SchedulerList setLoader={props.setLoader} />,
    },
    {
      path: "/historyList",
      element: <HistoryList setLoader={props.setLoader} />,
    },
    {
      path: "/instrumentInOutList",
      element: <InstrumentInOutList setLoader={props.setLoader} />,
    },
    {
      path: "/editInstrumentInOut/:id",
      element: <EditInstrumentInOut setLoader={props.setLoader} />,
    },
    {
      path: "/createInstrumentInOut",
      element: <CreateInstrumentInOut setLoader={props.setLoader} />,
    },
    {
      path: "/instrumentInOutReport",
      element: <InstrumentInOutReport setLoader={props.setLoader} />,
    },
    {
      path: "/allocatedReport",
      element: <AllocatedReport setLoader={props.setLoader} />,
    },
    {
      path: "/summaryList",
      element: <SummaryList setLoader={props.setLoader} />,
    },
    {
      path: "/auditList",
      element: <AuditTable setLoader={props.setLoader} />,
    },
    {
      path: "/audit/viewLogs",
      element: <ViewLogs setLoader={props.setLoader} />,
    },
    {
      path: "/documentUpload",
      element: <ViewDocument setLoader={props.setLoader} />,
    },
    {
      path: "/documentUpload/create/:id",
      element: <CreateDocument setLoader={props.setLoader} />,
    },
  ];

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <HashRouter>
        {localStorage.getItem("isLoggedIn") && (
          <Navbar
            setThemecolor={PropsSetThemecolor}
            mode={PropsMode}
            setMode={PropsSetMode}
            title={title}
            setIsLoggedIn={setIsLoggedIn}
          />
        )}

        <Box
          component="main"
          sx={{ flexGrow: 1, pt: 9, width: "100%", height: "100%" }}
        >
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Box>
      </HashRouter>
      <ToastContainer />
    </Box>
  );
}
